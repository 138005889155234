import {NextPage} from 'next';
import React, {useEffect} from 'react';
import Catalog from 'components/pages/Catalog';
import {coachPath, learnPath, managePath} from 'utils/routeFactory';
import {verifyUser, VerifyUserInfo} from 'utils/serverSidePage/verifyUser';
import {useSession} from 'context/SessionContext';
import {useRouter} from 'next/router';
import {gsspWithNonce} from 'utils/gsspWithNonce';

const IndexPage: NextPage = () => {
  const router = useRouter();
  const {data: session} = useSession();

  useEffect(() => {
    if (session && router) {
      if (session.user.abilities?.teamsReport === true) {
        router.push(managePath({asUrl: true}).home());
      } else if (session.user.abilities?.coachDashboard === true) {
        router.push(coachPath({asUrl: true}).home());
      } else {
        router.push(learnPath({asUrl: true}).home());
      }
    }
  }, [router, session]);

  // temporarily default to catalog page until home page is developed
  return <Catalog />;
};

export default IndexPage;

export const getServerSideProps = gsspWithNonce(async (context) => {
  try {
    const {user} = (await verifyUser(context)) as VerifyUserInfo;

    if (!user) {
      return {
        props: {}
      };
    }

    if (user.abilities?.teamsReport === true) {
      // user is a manager
      return {
        redirect: {
          destination: managePath().home(),
          permanent: false
        }
      };
    } else if (user.abilities?.coachDashboard === true) {
      // user is a coach redirect to my coaching page
      return {
        redirect: {
          destination: coachPath().home(),
          permanent: false
        }
      };
    } else {
      // user is not a coach redirect to my learning page
      return {
        redirect: {
          destination: learnPath().home(),
          permanent: false
        }
      };
    }
  } catch (e) {
    return {
      props: {}
    };
  }
});
